import React from 'react';
import { Button } from '../../globalStyles';
import { GiRock } from 'react-icons/gi';
import { IconContext } from 'react-icons/lib';
import {
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingContainer,
  PricingCard,
  PricingCardInfo,
  PricingCardIcon,
  PricingCardPlan,
  PricingCardCost,
  PricingCardLength,
  PricingCardFeatures,
  PricingCardFeature
} from './Pricing.elements';
import useCMS from '../../hooks/useCMS';
import Guarantee from '../Guarantee';
import { getTextContent } from '../../Data/dataManager';



const Pricing = ({ jsonPath }) => {
  const texts = getTextContent(jsonPath);
  const { buyCardSection } = texts;


  return (
    <IconContext.Provider value={{ color: 'var(--section-background)', size: 64 }}>
      <PricingSection>
        <PricingWrapper>
          <PricingHeading>{buyCardSection.title}</PricingHeading>
          <PricingContainer>
            {texts?.Pricing?.map((feat) => (
              <PricingCard key={feat.title}>
                <PricingCardInfo>
                  {/* <PricingCardIcon>
                    <GiRock />
                  </PricingCardIcon> */}
                  <PricingCardPlan>{feat.title}</PricingCardPlan>
                  <PricingCardCost>R$ {feat.price}</PricingCardCost>
                  <PricingCardLength>{buyCardSection.subPrice}</PricingCardLength>
                  <PricingCardFeatures style={{ alignSelf: 'left' }}>
                    {feat.benefits.map((benefit) => (
                      <PricingCardFeature key={benefit}>{benefit}</PricingCardFeature>
                    ))}
                  </PricingCardFeatures>
                  <Button secondary onClick={() => {
                    window.location.href = feat.link
                  }}>Entrar agora</Button>
                </PricingCardInfo>
                  <Guarantee jsonPath={jsonPath} />

              </PricingCard>
            ))}

          </PricingContainer>
        </PricingWrapper>
      </PricingSection>

    </IconContext.Provider>
  );
}

export default Pricing
import React, { useState } from 'react';
import './styled.css'; // Importando o arquivo CSS
import { getTextContent } from '../../Data/dataManager';


const CheckboxList = ({jsonPath}) => {
  const texts = getTextContent(jsonPath);
  const [checkedItems, setCheckedItems] = useState(
    Array(6).fill(false) // Supondo que temos 6 itens, inicializados como false
  );

  // Array com os textos dos itens
  const itemsText = texts.checkboxSection.itemsText;

  const handleClick = (index) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = !newCheckedItems[index]; // Alterna o estado do item
    setCheckedItems(newCheckedItems);
  };

  return (
    <div className="container">

      {itemsText.map((text, index) => (
        <div
          key={index}
          className="checkbox-item"
          onClick={() => handleClick(index)} // Adiciona a função de clique
        >
          {/* <input
            type="checkbox"
            checked={checkedItems[index]}
            readOnly
            className="custom-checkbox"
          /> */}
          <label>{text}</label>
        </div>
      ))}
    </div>
  );
};

export default CheckboxList;

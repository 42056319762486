import React from 'react';
import './styled.css'; // Importando o arquivo CSS
import { getTextContent } from '../../Data/dataManager';


const Testimonial = ({ name, role, message, url,  }) => (
  <div className="testimonial-card">
    <h3>{name}</h3>
    <p className="role">{role}</p>
    <blockquote>{message}</blockquote>
    <img src={url} alt="Depoimento" className="testimonial-img-arredondada" />
  </div>
);

const Testimonials = ({jsonPath}) => {
  const texts = getTextContent(jsonPath);

  const { testimonials } = texts; // Importando depoimentos do JSON

  return (
    <div className="testimonials-section">
      <h2 className="testimonials-title">Conheça alguns dos alunos(as):</h2>
      <div className="testimonial-grid">
        {testimonials.map((testimonial, index) => (
          <Testimonial
            key={index}
            name={testimonial.name}
            role={testimonial.role}
            message={testimonial.message}
            url={testimonial.url}
          />
        ))}
      </div>
    </div>
  );
};

export default Testimonials;

import React from 'react';
import './styled.css'; // Importando o arquivo CSS
import { PricingHeading } from '../Pricing/Pricing.elements';
import { getTextContent } from '../../Data/dataManager';


const Curso = ({jsonPath}) => {
  const texts = getTextContent(jsonPath);

  const cursos = texts.cursos; // Importando cursos do JSON

  return (
    <>
      <div className='mult-xxxx'>
        <PricingHeading>Conteúdo do curso</PricingHeading>
      </div>

      <div className="curso">
        {cursos.map((modulo, index) => (
          <div className="modulo" key={index}>
            <div className="modulo-header">
              <div className="img-container">
                <img
                  src={modulo.imagem}
                  alt={`Imagem do módulo ${index + 1}`}
                  className="img-module"
                />
              </div>
              <h3>{modulo.titulo}</h3>
            </div>
            <ul>
              {modulo.aulas.map((aula, i) => (
                <li key={i}>{aula}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </>
  );
};

export default Curso;

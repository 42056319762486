import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import GlobalStyles from "./globalStyles";
import { Navbar, Footer } from "./components";
import Home from "./pages/HomePage/Home";
import OfficePage from "./pages/OfficePage/OfficePage";

import ScrollToTop from "./components/ScrollToTop";
import useCMS from "./hooks/useCMS";

function App() {
  const { data: cmsData } = useCMS({
    // route: "color",
    // populate: "populate=*",
  });

  useEffect(() => {
    const root = document.documentElement;

    cmsData?.primary &&
      root.style.setProperty("--primary", cmsData?.colors.primary);

    cmsData?.secondary &&
      root.style.setProperty("--secondary", cmsData?.colors.secondary);

    cmsData?.background &&
      root.style.setProperty("--background", cmsData?.colors.background);

    cmsData?.sectionBackground &&
      root.style.setProperty(
        "--section-background",
        cmsData?.colors.sectionBackground
      );

    cmsData?.text && root.style.setProperty("--text", cmsData?.colors.text);

    cmsData?.heading &&
      root.style.setProperty("--heading", cmsData?.colors.heading);
  }, [cmsData]);

  return (
    <Router>
      <GlobalStyles />
      <ScrollToTop />
      <Navbar />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/officePage" component={OfficePage} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;

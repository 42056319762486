import React from 'react';
import './styled.css'; // Importando o arquivo CSS
import { getTextContent } from '../../Data/dataManager';


const Course = ({jsonPath}) => {
  const texts = getTextContent(jsonPath);
  const { heading, items } = texts.courseSection;

  return (
    <div className='mult'>
      <h2>{heading}</h2>

      <div className="curso-container">
        {items.map((item, index) => (
          <div className="curso-item" key={index}>
            <img src={item.img} alt={item.title} className="img-arredondada" />
            <h3 style={{ color: '#B47448', fontSize: '1.2rem' }}>{item.title}</h3>
            <p style={{ color: 'black', fontSize: '1rem' }}>{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Course;

import React from 'react';
import './styled.css'; // Importando o arquivo CSS
import { PricingHeading } from "../Pricing/Pricing.elements";
import { getTextContent } from '../../Data/dataManager';


const YouTubeVideo = ({jsonPath}) => {
  const texts = getTextContent(jsonPath);

  const { youtubeVideo } = texts;

  return (
    <div className="youtube-wrapper">
      <PricingHeading className="pricing-heading">{youtubeVideo.title}</PricingHeading>
      <div className="video-container">
        <iframe
          src={`https://www.youtube.com/embed/${youtubeVideo.videoId}`}
          title="Primeira aula | MAP"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default YouTubeVideo;

import React from 'react';
import './styled.css'; // Importando o arquivo CSS
import { getTextContent } from '../../Data/dataManager';


const Profile = ({jsonPath}) => {
  const texts = getTextContent(jsonPath);

  const { profile } = texts; // Importando perfil do JSON

  return (
    <div className="profile">
      <div className="profile-module">
        <div className="profile-header">
          <div className="img-container">
            <img src={profile.imagem} alt="Editores de vídeo" className="img-aaaa" />
          </div>
          <div className="profile-info">
            <span className="profile-title">{profile.titulo}</span>
            <span className="profile-description">{profile.description}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;

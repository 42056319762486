import React, { useState, useEffect } from "react";
import { InfoSec, InfoRow, InfoColumn, TextWrapper, TopLine, Heading, Subtitle, ImgWrapper, Img } from './InfoSection.elements'
import { Container, Button } from '../../globalStyles'
import { Link } from 'react-router-dom'
import { getTextContent } from '../../Data/dataManager';


import CheckboxList from "../CheckboxList";

const InfoSection = ({jsonPath
}) => {
  const texts = getTextContent(jsonPath);

  const {title} = texts.checkboxSection;

  const [checkedItems, setCheckedItems] = useState(
    Array(5).fill(false) // Supondo que temos 5 itens
  );


  return (
    <>

      <InfoSec lightBg={true}>
        <Container>
          <InfoRow imgStart={'start'}>
            <InfoColumn>

              <TextWrapper>
                <Heading lightText={false}>{title}</Heading>
                <CheckboxList jsonPath={jsonPath} />
              </TextWrapper>
            </InfoColumn>

          </InfoRow>
        </Container>
      </InfoSec>
    </>
  )
}

export default InfoSection;
import React from 'react';
import './styled.css'; // Importando o arquivo CSS
import { getTextContent } from '../../Data/dataManager';


const Guarantee = ({jsonPath}) => {
  const texts = getTextContent(jsonPath);
  const { image, title, description } = texts.guarantee;

  return (
    <div className="guarantee-container">
      <div className="guarantee-image">
        <img src={image} alt="Garantia" />
      </div>
      <div className="guarantee-text">
        <div className="guarantee-title">{title}</div>
        <div className="guarantee-description">
          {description}
        </div>
      </div>
    </div>
  );
};

export default Guarantee;

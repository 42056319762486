import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Source Sans Pro', sans-serif;
}
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}
*::-webkit-scrollbar {
  width: 0.5px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}
`;


export const Container = styled.div`
 align-items: center;
 justify-content: center;
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const ContainerCenter = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: 991px) {
 
  }
`;



export const Button = styled.button`
  border-radius: 4px;
  background: ${({ primary }) => (primary ? 'var(--primary)' : 'var(--secondary)')};
  white-space: nowrap;
  padding: ${({ big }) => (big ? '10px 48px' : '10px 20px')};

  color: var(--background);
  font-size: ${({ fontBig }) => (fontBig ? '20px' : '26px')};
  outline: none;
  border: none;
  cursor: pointer;

  @media screen and (max-width: 960px) {
    width: 100%;
  }

  @media screen and (min-width: 800px) {
    &:hover {
    transition: all 0.3s ease-out;
    background: var(--primary);
    background-color: ${({ primary }) => (primary ? 'var(--secondary)' : 'var(--success)')};
    color: ${({ primary }) => (primary ? 'var(--success)' : 'var(--secondary)')};
  } 
  }
`;




export default GlobalStyles;   
import HomeData from '../Data/Home.json';
import OfficeData from '../Data/OfficePage.json';


export const getTextContent = (jsonPath) => {
  if (jsonPath === 'Home') {
    return HomeData;
  } else if ((jsonPath === 'Office')) {
    return OfficeData;
  } 
};

import React from 'react';
import './styled.css'; // Criar um arquivo CSS separado para estilização
import { getTextContent } from '../../Data/dataManager';

const BonusItem = ({ title, description, icon }) => (
  <div className="bonus-item">
    <img src={icon} alt={title} className="bonus-icon" />
    <div>
      <h3>{title}</h3>
      <span className="bonus-description">{description}</span>
    </div>
  </div>
);

const BonusItemOlder = ({ title, description, icon }) => (
  <div className="bonus-item">
    <img src={icon} alt={title} className="bonus-icon-older" />
    <div>
      <h3>{title}</h3>
      <span className="bonus-description-older">{description}</span>
    </div>
  </div>
);

const BonusSection = ({ jsonPath }) => {
  const texts = getTextContent(jsonPath);
  const { title, items } = texts.bonusSection;

  return (
    <div className="bonus-grid">
      <span className="bonus-title">{title}</span>
      <div className="bonus-items-rows">
        <div className="bonus-items">
          {items
            .filter(item => !item.isOlder)
            .map((item, index) => (
              <BonusItem
                key={index}
                title={item.title}
                description={item.description}
                icon={item.icon}
              />
            ))}
        </div>
        <div className="bonus-items">
          {items
            .filter(item => item.isOlder)
            .map((item, index) => (
              <BonusItemOlder
                key={index}
                title={item.title}
                description={item.description}
                icon={item.icon}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default BonusSection;

import React, { useState } from 'react';
import './styled.css'; // Importando o arquivo CSS
import { getTextContent } from '../../Data/dataManager';


const FAQItem = ({ question, answer, isOpen, onClick }) => (
  <div
    className={`faq-question ${isOpen ? 'open' : ''}`}
    onClick={onClick}
  >
    <div className="faq-header">
      <h3 className="title">
        <b className="medium-b">{question}</b>
      </h3>
      <div className={`arrow ${isOpen ? 'open' : ''}`} />
    </div>
    {isOpen && (
      <div className="content">
        <div className="text">{answer}</div>
      </div>
    )}
  </div>
);

const FAQ = ({jsonPath}) => {
  const texts = getTextContent(jsonPath);

  const [openIndex, setOpenIndex] = useState(null);
  const faqData = texts.faqSection; // Acesse os dados da FAQ do JSON

  const handleClick = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  return (
    <div className="faq-container">
      <h2 className="faq-title">Perguntas Frequentes</h2>
      {faqData.length === 0 ? (
        <p>Carregando...</p>
      ) : (
        faqData.map((faq, index) => (
          <FAQItem
            key={index}
            question={faq.question}
            answer={faq.answer}
            onClick={() => handleClick(index)}
            isOpen={openIndex === index}
          />
        ))
      )}
    </div>
  );
};

export default FAQ;

import React from 'react';
import { FaWhatsapp, FaInstagram } from 'react-icons/fa'; // Biblioteca de ícones
import './styled.css'; // Importando o arquivo CSS
import { getTextContent } from '../../Data/dataManager';

const ContactUs = ({jsonPath}) => {
  const texts = getTextContent(jsonPath);

  const { title, whatsappLink, instagramLink } = texts.contactSection;

  return (
    <div className="contact-container">
      <h2 className="contact-title">{title}</h2>
      <div className="contact-buttons">
        <a
          href={whatsappLink} // Link do WhatsApp do JSON
          className="contact-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp size={24} /> Mande mensagem pelo WhatsApp
        </a>
        <a
          href={instagramLink} // Link do Instagram do JSON
          className="contact-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram size={24} /> Entre em contato pelo Instagram
        </a>
      </div>
    </div>
  );
};

export default ContactUs;

import React, { useState } from "react";
import { InfoSec, InfoRow, InfoColumn, TextWrapper, TopLine, Heading, Subtitle, ImgWrapper, Img, List, ListItem } from './InfoSection.elements';
import { Container, Button } from '../../globalStyles';
import { getTextContent } from '../../Data/dataManager';


const IntroSection = ({
  imgStart,
  start,
  listItems,
  jsonPath// Adicione esta linha para receber listItems como props
}) => {
  const texts = getTextContent(jsonPath);

  const { title, subtitle, img, buttonGuarantee,link } = texts.IntroSection;

  const [checkedItems, setCheckedItems] = useState(
    Array(5).fill(false) // Supondo que temos 5 itens
  );

  const handleMouseEnter = (index) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = true;
    setCheckedItems(newCheckedItems);
  };

  const handleMouseLeave = (index) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = false;
    setCheckedItems(newCheckedItems);
  };

  return (
    <InfoSec lightBg={false}>
      <Container>
        <InfoRow imgStart={imgStart}>
          <InfoColumn>
            <TextWrapper>
              <TopLine lightTopLine={true}>{title}</TopLine>
              <Heading lightText={true}>{subtitle}</Heading>
              {/* <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle> */}

              {listItems && (
                <List>
                  {listItems.map((item, index) => (
                    <ListItem key={index}>{item}</ListItem>
                  ))}
                </List>
              )}

              <Button big fontBig primary={true} onClick={() => {
                window.location.href = link
              }}>
               {buttonGuarantee}
              </Button>
            </TextWrapper>
          </InfoColumn>
          <InfoColumn>
            <ImgWrapper start={start}>
              <Img src={img} alt={'alt'} />
            </ImgWrapper>
          </InfoColumn>
        </InfoRow>
      </Container>
    </InfoSec>
  );
}

export default IntroSection;

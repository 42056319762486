import React, { useState, useEffect } from "react";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  HamburgerIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavItemBtn,
  NavBtnLink,
} from "./Navbar.elements";
import { FaTimes, FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { Button } from "../../globalStyles";
import useCMS from "../../hooks/useCMS";

import texts from "../../Data/data.json"


const { logo } = texts;



function Navbar() {


  
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const [homeClick, setHomeClick] = useState(false);
  const [servicesClick, setServicesClick] = useState(false);
  const [productsClick, setProductsClick] = useState(false);

  const handleHomeClick = () => {
    setHomeClick(true);
    setProductsClick(false);
    setServicesClick(false);
  };
  const handleServicesClick = () => {
    setHomeClick(false);
    setProductsClick(false);
    setServicesClick(true);
  };
  const handleProductsClick = () => {
    setHomeClick(false);
    setProductsClick(true);
    setServicesClick(false);
  };

  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    // so if the screensize is <= 960px then set button state to false
    if (window.innerwidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  const { data: cmsData } = useCMS();

  window.addEventListener("resize", showButton);

  return (
    <>
      <IconContext.Provider value={{ color: "var(--primary)" }}>
        <Nav>
          <NavbarContainer>
            <NavLogo to="/">
              {/* <NavIcon /> */}
              <img src="./Logotipo.png" alt="Descrição da imagem" className="small-image"/>
              {/* {logo.logoText} */}
            </NavLogo>
            <HamburgerIcon onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </HamburgerIcon>
            {/* <NavMenu onClick={handleClick} click={click}>
              {cmsData?.features.map((feat) => (
                <NavItem
                  key={feat.link}
                  onClick={handleServicesClick}
                  servicesClick={servicesClick}
                >
                  <NavLinks to={feat.link} onClick={closeMobileMenu}>
                    {feat.title}
                  </NavLinks>
                </NavItem>
              ))}

              <NavItemBtn>
                {button ? (
                  <NavBtnLink to="/sign-up">
                    <Button primary>SIGN UP</Button>
                  </NavBtnLink>
                ) : (
                  <NavBtnLink to="/sign-up">
                    <Button onClick={closeMobileMenu} fontBig primary>
                      SIGN UP
                    </Button>
                  </NavBtnLink>
                )}
              </NavItemBtn>
            </NavMenu> */}
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;

import { useEffect, useState } from "react";
import response from "../Data/Home.json";
// import useFetch from "./useFetch";

const useCMS = (
  // { route, populate }
) => {
  const [data, setCMSData] = useState(null);
  const [header, setCMSHeader] = useState(null);
  const [status, setCMSStatus] = useState(null);

  // const { isFetching, response, error } = useFetch(
  //   `http://localhost:1337/api/${route}${populate ? "?" + populate : ""}`
  // );
  
  useEffect(() => {
    if (response !== undefined && response !== null) {
      setCMSData(response.data);
      setCMSHeader(response.headers);
      setCMSStatus(response.status);
    }
  }, [response]);

  return {
    data,
    header,
    status,
    // isFetching,
    // error,
  };
};

export default useCMS;

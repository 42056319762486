import React, { useState, useEffect } from "react";
import { InfoSec, InfoRow, InfoColumn, TextWrapper, TopLine, Heading, Subtitle, ImgWrapper, Img } from './InfoSection.elements'
import { Container, Button } from '../../globalStyles'

import Faq from "../Faq";
import ContactUs from "../ContactUs";

const InfoSection = ({ jsonPath }

 ) => {



  return (
    <>
      <InfoSec lightBg={true}>
        <Container>
          <InfoRow>
            <InfoColumn>
              <TextWrapper>
                <Faq jsonPath={jsonPath} />

              </TextWrapper>
              <ContactUs jsonPath={jsonPath} />
            </InfoColumn>
          </InfoRow>
        </Container>
      </InfoSec>
    </>
  )
}

export default InfoSection;
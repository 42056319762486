import React, { useState, useEffect } from "react";
import { InfoSec, InfoRow, InfoColumn, TextWrapper, TopLine, Heading, Subtitle, ImgWrapper, Img } from './InfoSection.elements'
import { Container, Button } from '../../globalStyles'
import { Link } from 'react-router-dom'

import Profile from "../Profile";

const InfoSection = ({jsonPath
}) => {

  const [checkedItems, setCheckedItems] = useState(
    Array(5).fill(false) // Supondo que temos 5 itens
  );


  return (
    <>
      <InfoSec lightBg={true}>
        <Container>
          <InfoRow>
            <InfoColumn>
              <TextWrapper>
                <Profile jsonPath={jsonPath} />
              </TextWrapper>
            </InfoColumn>
          </InfoRow>
        </Container>
      </InfoSec>
    </>
  )
}

export default InfoSection;
import React from 'react';
import { InfoSection, Pricing, SectionCheckboxList, SectionCourse, SectionVideoMarketing, SectionModules, SectionProfile, SectionTestimony, SectionFaq, BonusSection } from '../../components';


const pageId = 'Office'

const OfficePage = () => {
  return (
    <>
      <InfoSection  jsonPath= {pageId} />
      <SectionCheckboxList jsonPath= {pageId} />
      <SectionCourse  jsonPath= {pageId} />
      <Pricing  jsonPath= {pageId} />
      <SectionVideoMarketing jsonPath= {pageId} />
      <SectionModules  jsonPath= {pageId} />
      <BonusSection  jsonPath= {pageId}/>
      <SectionProfile jsonPath= {pageId} />
      <SectionTestimony  jsonPath= {pageId} />
      <Pricing jsonPath= {pageId} />
      <SectionFaq jsonPath= {pageId} />
    </>
  )
}

export default OfficePage;
